.results-list {
    background-color: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px #ddd;
    border-radius: 5px;
    margin-top: 1rem;
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px;
    position: absolute;
    top: 125%;
    left: 50%;
    margin-right: -50%;
    line-height: 24px;
    transform: translate(-50%, -50%);
}

.search-result {
    padding: 10px 20px;
}

.search-result:hover {
    background-color: #efefef;
    cursor: pointer;
}

.search-box {
    border-radius: 2rem 0 0 2rem;
}

.search-button {
    border-radius: 0 2rem 2rem 0;
}